import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default {
  state: () => ({
    scrollPos: 0,
    authOpened: false,
  }),
  mutations: {
    setScrollPos(state, payload) {
      state.scrollPos = payload;
    },
    openAuth(state) {
      state.authOpened = true;
    },
    closeAuth(state) {
      state.authOpened = false;
    },
  },
  actions: {},
};
