<template>
  <div></div>
</template>

<script>
export default {
  name: 'Error',
  props: ['error'],
  beforeMount() {
    // eslint-disable-next-line no-restricted-globals
    // location.href = '/';
  },
};
</script>

<style scoped></style>
