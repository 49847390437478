import Vue from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';

window.onNuxtReady(()=> {
  Vue.use(VueReCaptcha, {
    siteKey: window.$nuxt.$config.recaptchaPublicKeyV3,
    loaderOptions: {
      useRecaptchaNet: false,
      autoHideBadge: true,
    },
  });
})
